export const getAzureConfig = () => {
    return {
      appId: "2940b826-09d5-43c8-bc1e-4c6295255a0c",
      authority: "https://login.microsoftonline.com/common",
      ssoRedirectPage: `${getSSOHost()}/accounts/login/sso/azure`,
      redirectUri: getSSOHost(),
      scopes: ["user.read"],
    };
};


const thisHost = window.location.host;


const getSSOHost = () => {
    let host = thisHost;
    if (!host.includes("localhost")) {
      host = thisHost.includes("test.sustainabilitytool.com")
        ? "uk.test.sustainabilitytool.com"
        : "uk.sustainabilitytool.com";
      host = `https://${host}`;
    } else {
      host = `http://${host}`;
    }
    return host;
};

