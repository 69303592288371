export const core_actions = [
  {
    sectors: ["Agriculture, forestry and fishing"],
    value:
      "Review and Improve livestock housing and manure storage solutions to reduce both methane and ammonia emissions. "
  },
  {
    sectors: ["Agriculture, forestry and fishing"],
    value:
      "Increase the use of precision feeding techniques to closely match feed rations with the animal’s nutritional requirements."
  },
  {
    sectors: ["Agriculture, forestry and fishing"],
    value:
      "Introduce nitrogen-fixing crops such as clover and other legume mixes into grass-only areas to reduce fertiliser usage and improve soil structure."
  },
  { sectors: ["All sectors"], value: "Reduce the amount of packaging for our products. " },
  {
    sectors: ["All sectors"],
    value:
      "Conduct a space optimisation study to determine the most efficient site layout to reduce emissions, cost, and waste."
  },
  { sectors: ["All sectors"], value: "Minimise or remove the use of single-use plastics." },
  { sectors: ["All sectors"], value: "Develop a waste strategy to reduce operational waste and encourage reuse." },
  { sectors: ["All sectors"], value: "Purchase low-carbon alternatives or recycled materials." },
  {
    sectors: ["All sectors"],
    value:
      "Consider alternative sources of water e.g. harvesting rainwater collected, recycling wastewater from sinks and showers."
  },
  { sectors: ["All sectors"], value: "Quantify our business's use of air freight and minimise where possible." },
  { sectors: ["All sectors"], value: "Maintain, repair, and/or upgrade poor energy performance equipment." },
  { sectors: ["All sectors"], value: "Explore the possibility of replacing our boilers with heat pumps." },
  { sectors: ["All sectors"], value: "Improve our building fabric insulation." },
  { sectors: ["All sectors"], value: "Seek opportunities for waste heat recovery." },
  {
    sectors: ["All sectors"],
    value: "Invest in onsite renewable energy such as roof mounted or integrated PVs or small-scale wind turbines."
  },
  { sectors: ["All sectors"], value: "Incentivise our employees to purchase electric vehicles." },
  { sectors: ["All sectors"], value: "Conduct an energy audit." },
  {
    sectors: ["Charities and non-for-profit"],
    value:
      "Calculate the energy and carbon footprint of your products and services and offer more sustainable products and services."
  },
  { sectors: ["Charities and non-for-profit"], value: "Use your platform to educate others on carbon reduction." },
  {
    sectors: ["Charities and non-for-profit"],
    value:
      "Monitor waste and maximise recycling, remove avoidable, single use and non-recyclable materials - such as promotional items."
  },
  {
    sectors: ["Construction"],
    value:
      "Assess possibilities to supply, deliver and support the training which will facilitate the rollout of heat pumps."
  },
  {
    sectors: ["Construction"],
    value: "Work towards using 'Modern Methods of Construction' on our projects such as offsite manufacturing."
  },
  { sectors: ["Construction"], value: "Monitor waste and maximise recycling on site." },
  { sectors: ["Construction"], value: "Invest in planning site logistics for people and deliveries" },
  {
    sectors: ["Construction"],
    value: "Ask our material suppliers to start sharing and displaying emissions information for their products."
  },
  {
    sectors: ["Construction"],
    value:
      "Check where our products have been manufactured and how far they have travelled to site. We will aim to buy locally-produced low-carbon materials where possible."
  },
  { sectors: ["Hospitality and tourism"], value: "Reduce our number of fridges and freezers." },
  { sectors: ["Hospitality and tourism"], value: "Offer up new plant-based options on our menus." },
  { sectors: ["Hospitality and tourism"], value: "Remove avoidable, single use and non-recyclable plastics." },
  { sectors: ["Hospitality and tourism"], value: "Measure our textile impacts." },
  {
    sectors: ["Manufacturing"],
    value: "Learn about extended producer responsibility (EPR) and how it may be relevant to the things we manufacture."
  },
  { sectors: ["Manufacturing"], value: "Change our site layout to increase efficiencies." },
  { sectors: ["Manufacturing"], value: "Replacing hot forging with cold forging where possible." },
  {
    sectors: ["Manufacturing"],
    value:
      "Look into computerised Numerical Control (CNC) to make equipment fully automated and programmable, improving efficiency. "
  },
  { sectors: ["Manufacturing"], value: "Service or upgrade our pump system." },
  {
    sectors: ["Manufacturing"],
    value: "Phase out and then ban all fossil fuel-powered plant tools - starting with hand tools."
  },
  { sectors: ["Manufacturing"], value: "Downsize motors to better suit demand." },
  { sectors: ["Manufacturing"], value: "Invest in motors with engine controls." },
  { sectors: ["Manufacturing"], value: "Consider heat recovery options for waste/excess heat." },
  {
    sectors: ["Oil, gas and quarrying"],
    value: "Make your site more efficient - reduce idling time and phase out fossil fuel-powered plant and equipment."
  },
  {
    sectors: ["Oil, gas and quarrying"],
    value: "Downsize motors to better suit demand and invest in motors with engine controls."
  },
  {
    sectors: ["Oil, gas and quarrying"],
    value:
      "Consider the products or materials you extract and produce and learn about new ways to create low-carbon solutions."
  },
  {
    sectors: ["Professional and business services"],
    value: "Calculate the energy and carbon footprint of our products and services."
  },
  {
    sectors: ["Professional and business services"],
    value: "#Allow for flexible working options that can help to reduce emissions."
  },
  { sectors: ["Public services"], value: "Create / support the creation of stakeholder networks for material chains." },
  { sectors: ["Public services"], value: "Appoint an internal, active, senior management sponsor." },
  {
    sectors: ["Public services"],
    value: "Develop a plan to prevent waste generation in municipal events and daily activities."
  },
  {
    sectors: ["Public services"],
    value:
      "Engage with local academic institutions to build knowledge and offer new solutions within your area of governance."
  },
  {
    sectors: ["Public services"],
    value: "Identify synergies relating to sustainability and carbon reduction across policies and plans."
  },
  { sectors: ["Public services"], value: "Promote existing funding opportunities for sustainable action." },
  {
    sectors: ["Public services"],
    value: "Where possible, open source and share relevant data relating to sustainable strategies and projects."
  },
  {
    sectors: ["Public services"],
    value:
      "Work with stakeholders to map existing carbon reduction-related initiatives across the whole city/district/region."
  },
  {
    sectors: ["Public services"],
    value:
      "Reach out to local community groups that have taken sustainable action and host community knowledge sharing sessions."
  },
  {
    sectors: ["Retail and wholesale"],
    value: "Offer or promote repair services to increase the lifespan of the products we sell."
  },
  { sectors: ["Retail and wholesale"], value: "Host a customer swap shop or collection point for unwanted items." },
  { sectors: ["Retail and wholesale"], value: "Remove non-essential packaging." },
  { sectors: ["Retail and wholesale"], value: "Encourage our customers to reuse packaging and bring in their own." },
  { sectors: ["Retail and wholesale"], value: "Offer our customers more sustainable products to buy." },
  {
    sectors: ["Technology and communication"],
    value: "Calculate the energy and carbon footprint of our products and services."
  },
  {
    sectors: ["Technology and communication"],
    value: "Allow for flexible working options that can help to reduce our emissions."
  },
  {
    sectors: ["Technology and communication"],
    value:
      "Consider using a load-balancing virtual machine (VM) or containerised server to reduce our energy consumption."
  },
  { sectors: ["Technology and communication"], value: "Look into migrating from on-premises servers to the cloud." },
  { sectors: ["Transport and storage"], value: "Invest in planning site logistics for people and deliveries." },
  { sectors: ["Transport and storage"], value: "Substitute fossil fuel with low carbon fuels, such as electricity." },
  { sectors: ["Transport and storage"], value: "Train drivers on more fuel-efficient driving practices." },
  { sectors: ["Utilities"], value: "Monitor waste and maximise recycling on site." },
  {
    sectors: ["Utilities"],
    value: "Encourage the adoption of renewable energy purchase and/or onsite generation for your customers."
  },
  {
    sectors: ["Utilities"],
    value: "Phase out and then ban all fossil fuel-powered plant tools - starting with hand tools."
  }
];

export const quick_wins = [
  {
    sectors: ["Agriculture, forestry and fishing"],
    value: "Invest in a cover for our slurry store or upgrade to a new slurry storage system."
  },
  {
    sectors: ["Agriculture, forestry and fishing"],
    value:
      "Read the Code of Good Agriculture Practice to find out what we can do to protect and enhance the quality of our soil."
  },
  {
    sectors: ["All sectors"],
    value: "Assign a green champion to undertake checks on energy, waste and carbon reduction opportunities."
  },
  { sectors: ["All sectors"], value: "Brainstorm with our employees on ways to reduce waste." },
  {
    sectors: ["All sectors"],
    value: "Consider the local sharing economy before purchasing new equipment e.g. repair cafes and tool libraries. "
  },
  {
    sectors: ["All sectors"],
    value: "Educate ourselves about the importance of saving water through online videos and newsletters."
  },
  {
    sectors: ["All sectors"],
    value:
      "Encourage staff to use lower carbon alternatives for commuting e.g. walking, cycling, public transport, car-sharing."
  },
  {
    sectors: ["All sectors"],
    value: "Encourage recycling by clear labelling e.g. waste, recycling, compost/food."
  },
  {
    sectors: ["All sectors"],
    value:
      "Ensure that our air conditioning and refrigeration/freezer systems are kept clean and free from dust, debris or obstruction."
  },
  { sectors: ["All sectors"], value: "Gather our employee views on sustainability." },
  { sectors: ["All sectors"], value: "Implement a 'train first' policy for business travel over air travel." },
  {
    sectors: ["All sectors"],
    value: "Install a smart meter and track our energy use weekly or monthly to look for potential energy wastage."
  },
  {
    sectors: ["All sectors"],
    value: "Keep radiators free from obstruction so heat isn't prevented from circulating around the room."
  },
  {
    sectors: ["All sectors"],
    value: "Look into our current pension provider for environmentally friendly investment opportunities. "
  },
  { sectors: ["All sectors"], value: "Perform an energy efficiency test." },
  { sectors: ["All sectors"], value: "Provide our cleaning staff with eco-friendly products." },
  {
    sectors: ["All sectors"],
    value: "Review the water efficiency of any equipment and upgrade where possible."
  },
  {
    sectors: ["All sectors"],
    value: "Seek out ways to ensure old equipment is recycled or reused when we no longer need it. "
  },
  { sectors: ["All sectors"], value: "Service our boilers once a year." },
  {
    sectors: ["All sectors"],
    value: "Take time to share some of our latest  actions to reduce our emissions with the wider community."
  },
  {
    sectors: ["All sectors"],
    value: "Train all staff on sustainable use of equipment and building management."
  },
  { sectors: ["All sectors"], value: "Upgrade our lighting with low energy LED bulbs." },
  {
    sectors: ["All sectors"],
    value: "Upgrade to smart thermostats (or cheaper automated timers) to turn on at specific times and locations."
  },
  { sectors: ["All sectors"], value: "Use timers and automatic light controls for our buildings." },
  { sectors: ["Charities and non-for-profit"], value: "Look into refurbished or repurposed furniture and equipment." },
  {
    sectors: ["Charities and non-for-profit"],
    value: "Deliver services remotely where possible using technology rather than travelling."
  },
  {
    sectors: ["Construction"],
    value: "Challenge our clients to share energy efficiency savings where we provide them with a lower carbon and more energy efficient design in operations."
  },
  {
    sectors: ["Construction"],
    value: "Look into and get involved with the Construction Leadership Council's CO2nstruct Zero Framework."
  },
  {
    sectors: ["Construction"],
    value: "Offer our clients alternative low-carbon solutions on design projects, even if not requested."
  },
  {
    sectors: ["Construction"],
    value: "Look for design and construction methods which reduce carbon intensive materials e.g. steel, concrete."
  },
  {
    sectors: ["Construction"],
    value: "Request lower carbon and energy-efficient solutions from clients, and share their savings."
  },
  {
    sectors: ["Construction"],
    value: "Review and consider supporting retrofit plans proposed by the Construction Leadership Council."
  },
  { sectors: ["Hospitality and tourism"], value: "Ensure our team turns off all equipment overnight/when not in use." },
  { sectors: ["Hospitality and tourism"], value: "Offer up half size portions." },
  {
    sectors: ["Hospitality and tourism"],
    value: "Print out the 6 Ways to Save Food Poster and share it around with staff before putting it up."
  },
  { sectors: ["Hospitality and tourism"], value: "Offer up a free water refill point." },
  { sectors: ["Manufacturing"], value: "Consider industrial insulation of our pipes and tanks." },
  { sectors: ["Manufacturing"], value: "Invest in ceramic bearings (to replace metal ones)." },
  { sectors: ["Manufacturing"], value: "Subscribe to a digital monitoring system to look out for efficiency leaks." },
  { sectors: ["Oil, gas and quarrying"], value: "Monitor waste and maximise recycling on site." },
  { sectors: ["Professional and business services"], value: "Delete data that is no longer needed." },
  {
    sectors: ["Professional and business services"],
    value: "Look into refurbished or repurposed office and ICT equipment."
  },
  {
    sectors: ["Professional and business services"],
    value: "Deliver services remotely where possible using technology rather than travelling."
  },
  {
    sectors: ["Public services"],
    value:
      "Use the OECD scoreboard to identify how “circular” the city / district is, areas of strength and areas to improve."
  },
  {
    sectors: ["Public services"],
    value: "Introduce, inform and encourage our staff on low-carbon commuting options. "
  },
  { sectors: ["Retail and wholesale"], value: "Move to e-receipts." },
  {
    sectors: ["Retail and wholesale"],
    value:
      "Get in touch with our local chamber of commerce or business group to seek out more local producers that we could stock."
  },
  { sectors: ["Technology and communication"], value: "Delete data that is no longer needed." },
  { sectors: ["Technology and communication"], value: "Programme with energy efficiency in mind." },
  {
    sectors: ["Technology and communication"],
    value: "Look into refurbished or repurposed ICT equipment before buying new."
  },
  { sectors: ["Transport and storage"], value: "Improve collection of mileage and fuel data." },
  {
    sectors: ["Utilities"],
    value: "Get clients to share energy efficiency savings when you request lower carbon & energy-effecient solutions."
  },
  { sectors: ["Utilities"], value: "Advise customers on methods to reduce their own energy consumption." },
  {
    sectors: ["Utilities"],
    value: "Include carbon and waste reduction in toolbox talks and onsite briefings for staff."
  },
  {
    sectors: ["Utilities"],
    value: "Look into further upskilling of staff with a focus on green credentials relevant to our business."
  }
];

export const transformations = [
  {
    sectors: ["Agriculture, forestry and fishing"],
    value:
      "Choose alternative crop varieties with increased nutrient-use efficiency, reducing nitrogen fertiliser inputs."
  },
  {
    sectors: ["All sectors"],
    value: "Collaborate on climate action technologies and share best known examples and methodologies."
  },
  {
    sectors: ["All sectors"],
    value:
      "Make sure new initiatives take into account current and future climate and any associated vulnerabilities at the outset."
  },
  { sectors: ["All sectors"], value: "Talk to our suppliers." },
  { sectors: ["All sectors"], value: "Electrify our vehicle fleet." },
  { sectors: ["All sectors"], value: "Offset the carbon you can't reduce." },
  {
    sectors: ["Hospitality and tourism"],
    value: "Set Circular Procurement Guidelines for our organisation's workwear and materials."
  },
  { sectors: ["Manufacturing"], value: "Look into onsite renewable energy generation." },
  {
    sectors: ["Manufacturing"],
    value:
      "Consider the products produced and research increasing quality, reducing downstream waste and extend their period of use."
  },
  { sectors: ["Manufacturing"], value: "Offer our products as a service." },
  { sectors: ["Oil, gas and quarrying"], value: "Look into onsite renewable energy generation." },
  {
    sectors: ["Retail and wholesale"],
    value: "Offer a returns scheme for unwanted items (in exchange for vouchers or discounts)."
  },
  { sectors: ["Transport and storage"], value: "Look into onsite renewable energy generation." },
  {
    sectors: ["Utilities"],
    value:
      "Phase out internal combustion engine (ICE), company owned, fleet vehicles with electric vehicle (EV) replacements."
  }
];
