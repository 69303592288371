import React from "react";
import LoginForm from "traec-react/auth/form";
import { BSCard } from "traec-react/utils/bootstrap";
import Octicon from "react-octicon";
import { map } from "jquery";
import { YoutubeEmbed } from "./youtubeEmbed";
import "./static.css";
import { getAzureConfig } from "../azure";
import TipsSpinner from "AppSrc/utils/spinners/tipsSpinner";

function Section({ children }) {
  return (
    <section className="home-section mt-5">
      <div className="container">
        <div className="row">{children}</div>
      </div>
    </section>
  );
}

function TitleSection() {
  return (
    <div className="container-fluid m-0 p-0">
      <div className="row" style={{ borderBottom: "1px solid blue" }}>
        <div className="col-sm-6 d-flex justify-content-center align-items-center">
          <h2>
            <b>Annual diversity survey</b>
          </h2>
        </div>
        <div className="col-sm-6 text-center">
          <img
            src={"/assets/images/logo/logo-diversity.png"}
            alt=""
            style={{ maxWidth: "80%" }}
            className="img-fluid m-0 p-0"
          />
        </div>
      </div>
    </div>
  );
}

const downloadableMetricAndFaqGuides = () => {
  return (
    <div className="col-sm-8 static-heading">
      <h2>
        <b>
          {" "}
          Measure & manage your organisation’s <u>carbon footprint</u> for FREE{" "}
        </b>
      </h2>
      <p>The Carbon Calculator is used by over 1000 Suppliers to monitor, manage & track their carbon emissions.</p>
      <p>Download our Metrics Guide and FAQ below</p>
      <div className="button-wrapper">
        <div class="vn-blue">
          <a
            href="https://github.com/ActionSustainability/static-content/raw/master/carbonCalculator/Carbon-Calculator-Metrics-Guide.xlsx"
            class="btn-wrap"
            download
          >
            metrics guide
          </a>
        </div>
        <div class="vn-blue">
          <a
            href="https://github.com/ActionSustainability/static-content/raw/master/carbonCalculator/FAQ-Carbon-Calculator-(Suppliers).xlsx"
            class="btn-wrap"
            download="FAQs"
          >
            FAQs
          </a>
        </div>
        <div class="vn-blue">
          <a
            href="https://github.com/ActionSustainability/static-content/raw/master/carbonCalculator/Indicator_FAQ.xlsx"
            class="btn-wrap"
            download="IndicatorFAQ"
          >
            Indicator FAQ
          </a>
        </div>
      </div>
    </div>
  );
};

const HomeScreenImageCard = ({ imageUrl, redirectUrl, imageAlt, title }) => {
  return (
    <div className="col-sm-12 col-md-4">
      <div className="card shadow p-3 mb-5 bg-white rounded border-0" style={{ cursor: "pointer" }}>
        <img
          src={`${imageUrl}`}
          className="card-image-top"
          style={{ filter: "brightness(50%)", minHeight: "250px", width: "100%", objectFit: "cover" }}
          alt={imageAlt ? `${imageAlt}` : "Sustainability tool image"}
        />
        <a href={`${redirectUrl}`} className="btn btn-sm btn-outline-primary mt-3" target="_blank">
          <div className="card-img-overlay d-flex justify-content-center align-items-center">
            <h3 className="card-title text-white text-center">{`${title}`}</h3>
          </div>
          Learn more
        </a>
      </div>
    </div>
  );
};

const headerStrapline = "Measure and Manage the carbon emissions of your organisation and supply chain";

const CifHeaderSection = () => {
  return (
    <h2 className="mb-5  mt-0 py-2 text-center font-weight-bold">
      <img className="rounded-sm" style={{ height: "150px" }} src="/assets/images/cif/CifLogoColour.png" />
      <br />
      {headerStrapline}
    </h2>
  );
};

function RegisterSection({ isAuthenticated }) {
  let is_sbcc = location.hostname.startsWith("sbcc") || location.hostname.includes("localhost");
  let is_cif = location.hostname.startsWith("cif") || location.hostname.includes("localhost");
  let createText = is_sbcc ? "Create an account" : "Create a supplier account";

  return (
    <div className="container">
      {is_cif ? <CifHeaderSection /> : <h2 className="my-5 py-5 text-center font-weight-bold">{headerStrapline}</h2>}
      {/* Register portal below */}
      <div className="row">
        <HomeScreenImageCard
          title="Measure your own emissions"
          redirectUrl={
            is_cif
              ? "https://landing.sustainabilitytool.com/CIF-landing-pages/cif-supplierlandingpage.html"
              : "https://landing.sustainabilitytool.com/Sustainability-tool--Partner-page/carbon%20calculator-%20measure%20your%20footprint%20-%20suppliers.html"
          }
          imageUrl={
            "https://github.com/ActionSustainability/static-content/blob/master/carbonCalculator/towersImage.png?raw=true"
          }
        />
        <HomeScreenImageCard
          title="Measure your supply chain emissions"
          redirectUrl={
            is_cif
              ? "https://landing.sustainabilitytool.com/CIF-landing-pages/cif-clientlandingpage.html"
              : "https://landing.sustainabilitytool.com/Sustainability-tool--Partner-page/carbon%20calculator%20-%20measure%20scope%203%20emissions%20-%20clients%20partners.html"
          }
          imageUrl={
            "https://github.com/ActionSustainability/static-content/blob/master/carbonCalculator/cargoImage.png?raw=true"
          }
        />
        <div className="col-sm-12 col-md-4 d-flex justify-content-center align-items-center">
          {isAuthenticated || isAuthenticated == "confirmed" ? (
            <BSCard
              title="Already logged in"
              widthOffset="col-sm-12 p-0"
              body={
                <div>
                  <p>You are already logged in</p>
                  <button className="btn btn-primary btn-sm" onClick={() => (location.href = "/accounts/profile")}>
                    Access account
                  </button>
                </div>
              }
            />
          ) : (
            <BSCard
              title="Log in"
              widthOffset="col-sm-12 p-0"
              body={<LoginForm createText={createText} azureConfig={getAzureConfig()} />}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function FlowBox({ text, arrow = true }) {
  return (
    <React.Fragment>
      <div className="home-flow-box text-center text-white">
        <span>
          <b>{text}</b>
        </span>
      </div>
      {arrow ? <Octicon name="arrow-right" mega={true} className="pl-2" /> : null}
    </React.Fragment>
  );
}

function RegisterFlowDiagram() {
  return (
    <Section>
      <div className="col-sm-12">
        <h3>
          <b>3 Easy Steps</b>
        </h3>
        <div className="col-sm-12 ml-0 mr-0 mt-4 d-flex flex-row justify-content-center align-items-center">
          <FlowBox text="Register" />
          <FlowBox text="Apportion Data" />
          <FlowBox text="Report" arrow={false} />
        </div>
      </div>
    </Section>
  );
}

function StepSection({ title, text, imgSrc, nextArrow = true }) {
  return (
    <Section>
      <div className="col-sm-7">
        <h3>
          <b>{title}</b>
        </h3>
        {text}
      </div>
      <div className="col-sm-5">
        <img src={imgSrc} alt="" style={{ maxWidth: "100%" }} className="img-fluid m-0 p-0" />
      </div>
      {nextArrow ? (
        <div className="col-sm-12 mt-5 d-flex justify-content-center" style={{ borderTop: "1px dashed blue" }}>
          <Octicon name="arrow-down" className="text-primary home-down-arrow" />
        </div>
      ) : null}
    </Section>
  );
}

function DateListItems({}) {
  let dates = ["25th August", "22nd September", "27th October", "24th November", "15th December"];
  return dates.map((dateStr, i) => <li key={i}>{dateStr} at 3.00-4.00pm via MS Teams</li>);
}

export function StaticContent(props) {
  return (
    <div>
      <RegisterSection {...props} />
    </div>
  );

  // Below is the legacy landing page content which is being replaced by the microsite content
  return (
    <React.Fragment>
      {/*<TitleSection />*/}

      <RegisterFlowDiagram />
      <Section>
        <div className="col-sm">
          <b>How to register</b>
          <YoutubeEmbed link="https://www.youtube.com/embed/mLi_RkYU_aU" />
        </div>
        <div className="col-sm">
          <b>How to apportion data</b>
          <YoutubeEmbed link="https://www.youtube.com/embed/vpJ8M3x5NCo" />
        </div>
        <div className="col-sm">
          <b>How to report data</b>
          <YoutubeEmbed link="https://www.youtube.com/embed/xMJGYgpX4L8" />
        </div>
      </Section>
      <Section>
        <div className="col-sm-12 margin-top-5">
          <h3>
            <b>Benefits of the Carbon Calculator </b>
          </h3>
          <p>Engaging in reporting your data will help you to:</p>
          <ul>
            <li>
              Measure emissions for FREE with our <b>simple calculator</b>
            </li>
            <li>
              Respond to multiple<b> clients reporting requirements</b>
            </li>
            <li>
              <b>Track your footprint</b> to inform your sustainability strategy & <b>cost saving</b>
            </li>
          </ul>
        </div>
        <div className="col-sm-12 margin-top-5">
          <h3>
            <b>How does the Carbon Calculator work?</b>
          </h3>
          <p>
            The Carbon Calculator helps organisations to measure and manage their carbon footprint, as well as share
            information with their clients who want to know the emissions embedded in their supply chains.
          </p>
          <p>
            The system uses the official UK government conversion factors, updated yearly as{" "}
            <a
              href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting"
              target="_blank"
            >
              <u>published here</u>
            </a>
            , and the BATH ICE conversion factors for construction materials. The methodologies for calculating an
            organisation’s emissions and for apportioning these to clients are in strict accordance with the{" "}
            <a href="https://ghgprotocol.org/" target="_blank">
              <u>Greenhouse Gas Protocol</u>
            </a>
            .
          </p>
          <h4>
            <b>Getting set up</b>
          </h4>
          <p>
            To get started, create{" "}
            <a
              className="text-primary"
              href="https://carbon.sustainabilitytool.com/accounts/register/?navbar"
              target="_blank"
            >
              <u>a free account</u>
            </a>{" "}
            or{" "}
            <a
              className="text-primary"
              href="https://carbon.sustainabilitytool.com/accounts/register/?navbar"
              target="_blank"
            >
              <u>join your company’s existing account</u>
            </a>{" "}
            . Upon registration, you will be required to select whether you wish to measure your footprint on a monthly,
            quarterly or yearly basis (please note, this cannot be subsequently modified).
          </p>
          <h4>
            <b>Reporting & apportioning</b>
          </h4>
          <p>
            Once registered, you can begin to report for specified time periods by entering either:
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                your operational activity data from across your business, such as fuel consumption, travel data; or
              </li>
              <li>your emissions broken down by scope 1, 2 and 3 emissions if you already know them.</li>
            </ol>
          </p>
          <div className="vn-blue">
            <a
              href="https://github.com/ActionSustainability/static-content/raw/master/carbonCalculator/Carbon-Calculator-Metrics-Guide.xlsx"
              className="btn-wrap"
              download
            >
              metrics guide
            </a>
          </div>
          <br></br>
          <p>
            Before submitting a report, you will be required to select your clients from a list of organisations and
            provide the percentage of turnover they represent for your organisation. This will be used to attribute your
            emissions to each of your clients, which is known as apportioning. Please note, client organisations you
            apportion carbon emissions to will not see the percentage figures you provide – they will only see absolute
            values of tonnes of carbon attributed to them by you. You can repeat the reporting & apportioning over time
            for each reporting period – either monthly, quarterly or yearly.
          </p>
          <p>
            Please note the data your provide should cover all your organisation’s activities. If you’re looking for a
            reporting tool to breakdown emissions by sites, projects and business units, please contact{" "}
            <a className="text-primary" href="mailto: charles.naud@actionsustainability.com" target="_blank">
              our sales team
            </a>
            .
          </p>
          <h4>
            <b>Monitoring</b>
          </h4>
          <p>
            Once your first report submitted, dashboards will provide a breakdown of your emissions over time. You can
            view your carbon footprint by scope (1, 2 and 3) or activity type. Track how you progress over time and set
            your own targets to help you achieve reductions in emissions.
          </p>
          <p>
            Client organisations who have carbon emissions apportioned to them can see similar dashboards with carbon
            emissions embedded within their supply chains, which constitutes part of their scope 3 emissions. If you
            wish to calculate your supply chain scope 3 emissions, please contact{" "}
            <a className="text-primary" href="mailto: charles.naud@actionsustainability.com" target="_blank">
              our sales team
            </a>
            .
          </p>
        </div>
      </Section>
      <Section>
        <div className="col-sm-12 text-center preLogin-logo-image">
          <img src="/assets/images/logo/logo.png" className="img-fluid" alt="Responsive image" />
        </div>
      </Section>

      <Section>
        <div className="col-sm-12">
          <h3>
            <b>Want to learn more about Carbon Emissions? </b>
          </h3>
          <p>
            Our top 3{" "}
            <a
              className="text-primary"
              href="https://learn.supplychainschool.co.uk/local/tlactionplans/resources.php?formats=Event%20or%20workshop"
            >
              Supply Chain School
            </a>{" "}
            resources for furthering your knowledge on carbon emissions:
          </p>
          <li>
            <a
              className="text-primary"
              href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=2340&modtype=url"
            >
              Scope 3 greenhouse emissions from industry
            </a>
          </li>
          <li>
            <a
              className="text-primary"
              href="https://learn.supplychainschool.co.uk/local/tlactionplans/resource_intro.php?id=6615&modtype=scorm"
            >
              Introduction to Climate Change and Carbon
            </a>
          </li>
          <li>
            <a
              className="text-primary"
              href="https://learn.supplychainschool.co.uk/local/tlactionplans/resources.php?term=carbon"
            >
              All resources for carbon
            </a>
          </li>
        </div>
      </Section>
      <div className="parter-logos-wrapper">
        <img src="https://raw.githubusercontent.com/ActionSustainability/static-content/master/ekfb/Partner-badge.png" />
      </div>

      <Section>
        <div className="col-sm-12">
          <h3>
            <b>What is the Climate Action Group?</b>
          </h3>
          <p>
            Over 140+ of the Supply Chain Sustainability School’s Partners and their suppliers are collaborating to meet
            their ambitious scienced-based targets and work towards net zero.
          </p>
          <p>
            Our aim is to drive a reduction in carbon emissions from the built environment. To make this possible, we
            need the support and engagement of the supply chain to start reporting their carbon performance through the
            Carbon Calculator.
          </p>
        </div>
      </Section>
      <Section>
        <div className="col-sm-12">
          <h3>
            <b>Contact information</b>
          </h3>
          <p>
            For further support please email{" "}
            <a
              href="mailto:info@sustainabilitytool.com?subject=Carbon Calculator - Message support&body=Please complete the details below and send the email. The Sustainability Tool team will then be glad to support your query. %0D%0A %0D%0A 
            1. Full name: %0D%0A 
            2. Company name: %0D%0A 
            3. The URL (link) of the page you require support with: %0D%0A 
            4. A screenshot of the page (if possible): %0D%0A 
            5. A short description of your issue or query: %0D%0A %0D%0A
            We aim to respond to all our queries within 24 hours."
              className="dropdown-item"
              target="_blank"
            >
              {" "}
              info@sustainabilitytool.com
            </a>{" "}
            or view our frequently asked questions.
          </p>
        </div>
        <div className="vn-blue">
          <a
            href="https://github.com/ActionSustainability/static-content/raw/master/carbonCalculator/FAQ-Carbon-Calculator-(Suppliers).xlsx"
            className="btn-wrap"
            download="FAQs"
          >
            FAQs
          </a>
        </div>
      </Section>
      <Section>
        <div className="col-sm-12">
          <p>For further information you can also review our latest webinar recording about the Carbon Calculator</p>
        </div>
        <div className="col-12">
          <YoutubeEmbed link="https://www.youtube.com/embed/-_xn1uQ6ef0" />
        </div>
      </Section>

      <br className="mt-5" />
    </React.Fragment>
  );
}
