import React, { useEffect } from "react";
import { connect } from "react-redux";
import Traec from "traec/index";
import VERSION from "AppAssets/text/VERSION.txt";
import BUILD_DATE from "AppAssets/text/BUILD_DATE.txt";
import BRANCH_NAME from "AppAssets/text/BRANCH_NAME.txt";

const Version = props => {
  let { version } = props;

  useEffect(() => {
    Traec.fetchRequired.bind({
      props,
      requiredFetches: [new Traec.Fetch("version", "read")]
    })();
  });

  if (!version) {
    return null;
  }
  return (
    <div>
      <h3>Current Versions</h3>
      <Track version={version} />
      <SustoolReact />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let version = state.getInPath("entities.version");
  return {
    version
  };
};

export default connect(mapStateToProps)(Version);

const SustoolReact = props => {
  return (
    <div className="mt-3">
      <h4>Sustainability Tool React</h4>
      <div className="ml-3 row">
        <div className="col-2">COMMIT:</div>
        <div className="col-6">{VERSION}</div>
      </div>
      <div className="ml-3 row">
        <div className="col-2">BUILD DATE:</div>
        <div className="col-6">{BUILD_DATE}</div>
      </div>
      <div className="ml-3 row">
        <div className="col-2">BRANCH:</div>
        <div className="col-6">{BRANCH_NAME}</div>
      </div>
    </div>
  );
};

const Track = props => {
  let { version } = props;
  return (
    <div className="mt-3">
      <h4>Track</h4>
      <div className="ml-3 row">
        <div className="col-2">DOCKER TAG:</div>
        <div className="col-6">{version.get("tag")}</div>
      </div>
      <div className="ml-3 row">
        <div className="col-2">COMMIT:</div>
        <div className="col-6">{version.get("commit")}</div>
      </div>
      <div className="ml-3 row">
        <div className="col-2">BUILD DATE:</div>
        <div className="col-6">{version.get("date")}</div>
      </div>
      <div className="ml-3 row">
        <div className="col-2">BRANCH:</div>
        <div className="col-6">{version.get("branch")}</div>
      </div>
    </div>
  );
};
