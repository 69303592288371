import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";

const DEFAULT_LOGO = "/assets/images/logo/logo.png";
const CIF_LOGO = "/assets/images/cif/CifLogoWhiteLarge.png";
const is_cif = location.hostname.startsWith("cif") || location.hostname.includes("localhost");

function DefaultBrand(props) {
  return (
    <Link to="/home">
      <img
        src={DEFAULT_LOGO}
        alt="carbon calculator logo"
        style={{ marginTop: "0.2em" }}
        height="55px"
        className="m-0 p-0"
      />
      {is_cif ? <img src={CIF_LOGO} alt="cif logo" height="75px" className="ml-3 p-0" /> : null}
    </Link>
  );
}

function Strapline({ text, linkto, separator }) {
  if (!text) {
    return null;
  }
  if (linkto) {
    text = <Link to={linkto}>{text}</Link>;
  }
  return (
    <React.Fragment>
      {separator} {text}
    </React.Fragment>
  );
}

function StraplineImages({ urls = "" }) {
  let imgStyle = { marginLeft: "1rem", alignItems: "bottom" };
  let images = urls.split(",").map((url, i) => <img key={i} src={url} style={imgStyle} height="50px" alt="" />);
  return <div style={{ flexGrow: "1", marginTop: "auto", marginBottom: "0.2rem" }}>{images}</div>;
}

export function NavBarBrand(props) {
  let { styles } = props;

  if (!styles || !styles.size) {
    return <DefaultBrand />;
  }

  let logourl = styles.get("navbarLogo") || DEFAULT_LOGO;

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <div style={{ fontSize: "1rem" }}>
          <img src={logourl} height="40px" alt="" />
          <br />
          <span style={{ fontSize: "1rem", marginTop: "0", color: "#ddd" }}>
            <Strapline text={styles.get("strapline1")} />
            <Strapline text={styles.get("strapline2")} linkto={styles.get("strapline2link")} separator="|" />
          </span>
        </div>
        <StraplineImages urls={styles.get("straplineImages")} />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  let styles = state.getInPath("ui.styles") || Traec.Im.Map();
  return { styles };
};

export default connect(mapStateToProps)(NavBarBrand);
