import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import Octicon from "react-octicon";
import Traec from "traec";
import Moment from "moment";
import { BasicSpinner } from "AppSrc/utils/spinners/basicSpinner";
const { Im } = Traec;

export function RefreshButton({ trigger, isRetrying }) {
  return isRetrying ? (
    <span className="float-right ml-1">
      <BasicSpinner type="grow" />
    </span>
  ) : (
    <span className="float-right ml-1" style={{ cursor: "pointer" }} onClick={() => trigger()}>
      <Octicon name="sync" />
    </span>
  );
}

export const getLastUpdateFromNow = lastUpdate => {
  return lastUpdate ? new Moment(lastUpdate).fromNow() : "unknown";
};

const _isStr = value => typeof value === "string" || value instanceof String;

const sleep = sec => new Promise(r => setTimeout(r, 1000 * sec));

const getToken = () => {
  return localStorage.getItem("token", undefined);
};

export const fetcher = async (url, method = "GET", data = {}, _headers = {}) => {
  let token = getToken();
  while (!_isStr(token) || token.length < 20) {
    //console.log("getToken retrying to get token", token);
    await sleep(1);
    token = getToken();
  }
  //console.log("getToken got a token string", token);

  let headers = {
    Authorization: `JWT ${token}`,
    "Content-Type": "application/json",
    ..._headers
  };
  //console.log("Calling API", method, url, headers);

  const response = await fetch(url, {
    method,
    headers,
    body: method == "GET" ? null : JSON.stringify(data)
  });

  const _data = await response.json();
  const responseHeaders = Object.fromEntries(response.headers.entries());
  //console.log("Got API response from", url, _data, responseHeaders);

  return {
    payload: Im.fromJS(_data),
    headers: responseHeaders
  };
};

const swrOptions = {
  revalidate: false,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const swrMutateOptions = {
  revalidate: false,
  populateCache: true
};

const urlFromArgs = (url, args) => {
  let requiredArgs = url.matchAll(/{(.*?)}/g);
  let _url = `${url}`;
  for (const [param, key] of requiredArgs) {
    if (!args || !args[key]) {
      return null;
    }
    _url = _url.replaceAll(param, args[key]);
  }
  return _url;
};

export default function useApi(url, args, genArgs) {
  let [isRetrying, setIsRetrying] = useState(false);
  let [lastUpdate, setLastUpdate] = useState("");

  let _url = urlFromArgs(url, args);
  //console.log("Executing useApi", url, _url == url ? "" : _url);

  const props = useSWR(_url, fetcher, swrOptions);
  const { trigger, isMutating } = useSWRMutation(url, () => fetcher(url), swrMutateOptions);
  const { trigger: triggerCacheUpdate, isMutating: isRefreshingCache } = useSWRMutation(
    url,
    () => fetcher(url, "GET", {}, { "cache-force-update": true }),
    swrMutateOptions
  );

  let { data: _data, isLoading, error } = props;
  let isError = !isLoading && !!error;
  let { payload: data, headers } = _data || {};

  useEffect(() => {
    // Update the lastUpdate from response headers
    let _lastUpdate = (headers || {})["cache-last-update"];
    if (_lastUpdate) {
      setLastUpdate(new Moment(_lastUpdate).toISOString());
    }
    console.log(`useApi updated lastUpdate (lastUpdate = ${_lastUpdate})`);

    // Try in the retry-after seconds
    let retry = (headers || {})["retry-after"];
    console.log(`useApi executing useEffect (retry-after = ${retry})`);
    if (retry) {
      //console.log("useApi retrying again after", retry);
      setIsRetrying(true);
      setTimeout(() => {
        //console.log("triggering useApi again");
        trigger();
      }, retry * 1000);
    } else {
      setIsRetrying(false);
    }
  }, [data, headers]);

  return {
    ...props,
    data,
    headers,
    url: _url,
    isError,
    args: data && !isError && genArgs ? genArgs(data) : null,
    trigger,
    triggerCacheUpdate: () => {
      setIsRetrying(true);
      triggerCacheUpdate();
    },
    isMutating,
    isFetching: isLoading || isMutating,
    isRetrying,
    lastUpdate
  };
}

const getFetchArgs = fetches =>
  Object.values(fetches)
    .map(i => i.args)
    .filter(i => i)
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

export function useApis(fetches, updateParams) {
  let args = getFetchArgs(fetches);
  if (updateParams) {
    updateParams(args);
  }
  //console.log("useApis has arguments", args);
  return {
    fetches,
    urls: Object.values(fetches).map(i => i.url),
    isLoading: () => Object.values(fetches).some(i => i.isLoading),
    isMutating: () => Object.values(fetches).some(i => i.isMutating),
    isFetching: () => Object.values(fetches).some(i => i.isFetching),
    mutate: keys => (keys || Object.keys(fetches)).map(key => mutate(fetches[key]?.url)),
    trigger: keys => (keys || Object.keys(fetches)).map(key => fetches[key]?.trigger()),
    args
  };
}

const hasUpdates = (update = {}, obj = {}) => {
  //console.log("Checking objects for updates", update, obj);
  let newKeys = Im.Set(Object.keys(update)).subtract(Im.Set(Object.keys(obj)));
  if (newKeys.size) {
    return true;
  }
  for (let key of Object.keys(update)) {
    if (update[key] != obj[key]) {
      return true;
    }
  }
};

export function useApiParams(_params) {
  let [params, setParams] = useState(_params || {});

  const updateParams = newParams => {
    if (newParams && hasUpdates(newParams, params)) {
      //console.log("Updating api parameter set");
      setParams({ ...params, ...newParams });
    }
  };

  return {
    params,
    updateParams,
    setParams
  };
}
