import React, { useState } from "react";
import { connect } from "react-redux";
import Traec from "traec";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { BSBtnDropdown, BSCard, BSBtn } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import {
  baseMetricFields,
  conversionFactorFields,
  conversionFactorGroupFields,
  conversionFactorGroupEditFields
} from "./form";
import { conversionFields } from "AppSrc/project/metrics/forms";
import { setAndShowModal } from "AppSrc/utils/modal";

const counter = { metric: 0 };
const MODAL_ID = "CommonAdminConversionFactorModal001";

const editConversionFactor = ({ conversionFactor, groupId }) => {
  // Get the title of the modal
  let conversionFactorId = conversionFactor.get("uid");
  let metricId = conversionFactor.getInPath("metric.uid") || "";
  let toUnit = conversionFactor.getInPath("toUnit");

  let fetch = new Traec.Fetch("tenant_admin_conversion", "patch", { conversionFactorId, groupId });
  fetch.updateFetchParams({
    preFetchHook: data => {
      if (groupId) {
        data.group = groupId;
      }
      //console.log("POSTING DATA", data);
      return data;
    },
    postSuccessHook: () => {
      $(`#${MODAL_ID}`).modal("hide");
      location.reload();
    }
  });

  setAndShowModal(MODAL_ID, {
    title: `Edit conversion factor from metric [${metricId.substring(0, 8)}] to ${toUnit}`,
    body: (
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(conversionFields).toJS()}
        initFields={conversionFactor}
        forceShowForm={true}
        hideUnderline={true}
      />
    )
  });
};

const deleteConversionFactor = ({ conversionFactor, groupId }) => {
  let conversionFactorId = conversionFactor.get("uid");
  let fetch = new Traec.Fetch("tenant_admin_conversion", "delete", { conversionFactorId, groupId });
  fetch.dispatch();
};

const syncWithLinked = ({ conversionFactor, groupId }) => {
  let metricId = conversionFactor.getInPath("metric.uid");
  let fetch = new Traec.Fetch("tenant_admin_dispatch", "post", {});
  fetch.updateFetchParams({
    preFetchHook: data => ({
      action: "SYNC_CONVERSION_FACTORS",
      payload: {
        metric_id: metricId
      }
    })
  });
  fetch.dispatch();
};

const dropDownLinks = props => {
  let { conversionFactor, groupId } = props;
  let links = [
    {
      name: "Edit in all Groups and Reports",
      onClick: e => {
        editConversionFactor(props);
      }
    },
    {
      name: "Copy to Linked Metrics",
      onClick: e => {
        syncWithLinked(props);
      }
    }
  ];
  if (groupId) {
    links = links.concat([
      {},
      {
        name: "Edit in this Group only",
        onClick: e => {
          editConversionFactor(props);
        }
      },
      {
        name: "Delete from only this Group",
        onClick: e => {
          deleteConversionFactor(props);
        }
      }
    ]);
  }
  links = links.concat([
    {},
    {
      name: "Delete",
      onClick: e => {
        deleteConversionFactor(props);
      }
    }
  ]);
  return links;
};

function ConversionFactorRow(props) {
  let { conversionFactor } = props;
  let baseMetric = conversionFactor.get("metric");
  let rowNum = counter.metric++;
  return (
    <React.Fragment>
      <div className="row" style={{ borderTop: "1px solid #ddd", backgroundColor: (rowNum + 1) % 2 ? "#E6E6E6" : "" }}>
        <div className="col-sm-1">{conversionFactor.get("uid").substring(0, 8)}</div>
        <div className="col-sm-1">{baseMetric.get("uid").substring(0, 8)}</div>
        <div className="col-sm-6">{baseMetric.get("name")}</div>
        <div className="col-sm-1">{baseMetric.get("unit")}</div>
        <div className="col-sm-1">{conversionFactor.get("toUnit")}</div>
        <div className="col-sm-1">{conversionFactor.get("factor")}</div>
        <div className="col-sm-1">{<BSBtnDropdown links={dropDownLinks(props)} />}</div>
      </div>
    </React.Fragment>
  );
}

function ConversionFactorOptions({ groups }) {
  groups = groups.unshift(Traec.Im.fromJS({ id: null, name: "" }));
  return groups.toList().map((item, i) => (
    <option key={i} value={item.get("id")}>
      {item.get("name")}
    </option>
  ));
}

function ConversionFactorGroupSelect({ groups, onChangeHandler }) {
  return (
    <div className="form-group">
      <label>Current Group</label>
      <select className="form-control" onChange={onChangeHandler}>
        <ConversionFactorOptions groups={groups} />
      </select>
    </div>
  );
}

function ConversionFactorGroupEdit({ group }) {
  if (!group) {
    return null;
  }
  let fetch = new Traec.Fetch("tenant_admin_conversion_group", "put", { groupId: group.get("id") });
  return (
    <BaseFormConnected
      params={fetch.params}
      fields={conversionFactorGroupEditFields}
      initFields={group}
      forceShowForm={true}
      hideUnderline={true}
      submitBtnText={"Update"}
    />
  );
}

class ConverionFactorGroupForm extends React.Component {
  constructor(props) {
    super(props);

    this.fetch = new Traec.Fetch("tenant_admin_conversion_group", "post");

    this.state = {
      formParams: this.fetch.params
    };

    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate() {
    let { groups } = this.props;
    conversionFactorGroupFields.from_group_id.options = <ConversionFactorOptions groups={groups} />;
  }

  onClick(e) {
    e.preventDefault();
    this.fetch.toggleForm();
  }

  render() {
    let { groups, currentGroup, onChangeHandler } = this.props;
    return (
      <BSCard
        widthOffset="col-sm-12 m-0 p-0"
        title="Conversion Factor Groups"
        button={<BSBtn onClick={this.onClick} text="New Group" />}
        body={
          <ErrorBoundary>
            <ConversionFactorGroupSelect groups={groups} onChangeHandler={onChangeHandler} />
            <ConversionFactorGroupEdit group={currentGroup} />
          </ErrorBoundary>
        }
        form={<BaseFormConnected params={this.state.formParams} fields={conversionFactorGroupFields} />}
      />
    );
  }
}

function BSModal({ body, title }) {
  return (
    <div
      className="modal fade"
      id="baseMetricForm"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{body}</div>
        </div>
      </div>
    </div>
  );
}

class AddMetricForm extends React.Component {
  constructor(props) {
    super(props);

    let { groupId } = this.props;
    // The fetch for creating a basemetric
    this.bmfetch = new Traec.Fetch("tenant_admin_basemetric", "post");
    this.bmfetch.updateFetchParams({
      postSuccessHook: data => {
        this.setState({ metric_id: data.uid });
      }
    });
    // The fetch for creating a conversion factor
    this.fetch = new Traec.Fetch("tenant_admin_conversion", "post");
    this.fetch.updateFetchParams({
      preFetchHook: data => ({ ...data, group: groupId })
    });

    this.state = {
      formParams: this.fetch.params,
      bmFormParams: this.bmfetch.params,
      metric_id: null
    };
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    let { metric_id } = this.state;
    let fields = conversionFactorFields;
    let bmFields = { ...baseMetricFields };
    delete bmFields.conversion_base;
    bmFields.unit.endRow = true;
    bmFields.unit.class = "col-sm-4";

    // Set the field value
    fields.metric.value = metric_id ? metric_id.substring(0, 8) : null;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12">
            <a className="text-primary" style={{ cursor: "pointer" }} data-toggle="modal" data-target="#baseMetricForm">
              Get or Create a Base Metric
            </a>
          </div>
        </div>
        <div className="row">
          <BSModal
            title="Get or Create Base Metric"
            body={
              <React.Fragment>
                <p>
                  <b>
                    Note that all fields (including description) must match exactly otherwise a new Base Metric will be
                    created.
                  </b>
                </p>
                <BaseFormConnected forceShowForm={true} params={this.state.bmFormParams} fields={bmFields} />
              </React.Fragment>
            }
          />

          <BaseFormConnected
            forceShowForm={true}
            params={this.state.formParams}
            fields={fields}
            initFields={Traec.Im.fromJS({
              metric: metric_id ? metric_id.substring(0, 8) : null
            })}
            postChangeHook={e => {
              if (e.target.name == "metric") {
                this.setState({ metric_id: e.target.value });
              }
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

function AdminMenu({ groupId }) {
  const [state, setState] = React.useState({
    showAddMetricForm: false
  });

  if (!groupId) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="btn btn-sm btn-primary mb-3 mt-2">
          <BSBtnDropdown
            header={"Admin"}
            links={[
              {
                name: "Add a Metric",
                onClick: e => {
                  setState({ showAddMetricForm: true });
                }
              }
            ]}
          />
        </div>
      </div>
      <AddMetricForm show={state.showAddMetricForm} groupId={groupId} />
    </React.Fragment>
  );
}

class ConversionFactorAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupId: null
    };

    this.requiredFetches = [
      new Traec.Fetch("tenant_admin_conversion_group", "list"),
      new Traec.Fetch(
        "tenant_admin_conversion",
        "list",
        {},
        {
          preUpdateHook: args => ({ ...args, groupId: this.state.groupId })
        }
      ),
      new Traec.Fetch("tenant_admin_basemetric", "list")
    ];
  }

  /**********************
     COMPONENT METHODS
   **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  render() {
    let { conversionFactors, conversionFactorGroups, basemetrics } = this.props;

    // Get the set of conversion factors to render (if groupId==null then render all)
    let _conversionFactors = conversionFactors;
    if (this.state.groupId) {
      _conversionFactors =
        conversionFactorGroups.getInPath(`${this.state.groupId}.conversionFactors.byId`) || Traec.Im.Map();
      _conversionFactors = _conversionFactors.toList();
    }

    let rows = _conversionFactors
      .sortBy(i => (i.getInPath("metric.name") ? i.getInPath("metric.name").trim() : i.getInPath("metric.name")))
      .map((convFact, i) => (
        <ConversionFactorRow
          key={i}
          conversionFactor={convFact}
          basemetrics={basemetrics}
          groupId={this.state.groupId}
        />
      ));

    return (
      <div className="container-fluid">
        <h3>Conversion Factors in Tenancy</h3>
        <p>This gives a list of all Conversion Factors within this tenancy</p>

        <div className="row">
          <ConverionFactorGroupForm
            groups={conversionFactorGroups.toList()}
            currentGroup={conversionFactorGroups.get(this.state.groupId)}
            baseMetrics={basemetrics}
            onChangeHandler={e => {
              this.setState({ groupId: e.target.value });
            }}
          />
        </div>

        <AdminMenu groupId={this.state.groupId} />

        {/* Row header*/}
        <div className="row" style={{ fontWeight: "bold" }}>
          <div className="col-sm-1">id</div>
          <div className="col-sm-1">fromMetricId</div>
          <div className="col-sm-6">fromMetricName</div>
          <div className="col-sm-1">fromMetricUnit</div>
          <div className="col-sm-1">toUnit</div>
          <div className="col-sm-1">factor</div>
          <div className="col-sm-1">admin</div>
        </div>

        {rows}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let conversionFactorGroups = state.getInPath("entities.conversionFactorGroups.byId") || Traec.Im.Map();
  let conversionFactors = (state.getInPath("entities.conversionFactors.byId") || Traec.Im.Map()).toList();
  let basemetrics = state.getInPath("entities.baseMetrics.byId") || Traec.Im.Map();
  return { conversionFactors, conversionFactorGroups, basemetrics };
};

export default connect(mapStateToProps)(ConversionFactorAdmin);
