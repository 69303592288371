import Im from "immutable";
import Moment from "moment";
import { v4 as uuidv4 } from "uuid";
import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import Octicon from "react-octicon";

import store from "traec/redux/store";
import Traec from "traec";
import { ErrorBoundary } from "traec-react/errors/handleError";

import { ReportingPeriodRangePicker } from "storybook-dashboard/dashboard/components/reportingPeriodRangePicker";
import { YearSelector, useYearSelector } from "storybook-dashboard/components/yearRangePicker";
import { usePostCache } from "storybook-dashboard/utils/fetching";

import useApi, { getLastUpdateFromNow, RefreshButton } from "AppSrc/utils/hooks/fetching";
import TipsSpinner from "AppSrc/utils/spinners/tipsSpinner";
import { quick_wins, core_actions, transformations } from "./sbcc_actions";
import { setPrecision } from "AppSrc/utils/formatting";

const blobToDownload = (blob, fileName = "Carbon reduction plan.pdf") => {
  let url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const makeReport = (_id, payload, format = "pdf") => {
  //console.log("Making report with payload data", payload?.toJS());

  let url = `/api/report/sbcc/${_id}/${format}`;
  let state = store.getState();

  let token = state.getInPath("auth.token");
  //console.log("Calling api report with token", url, token, "payload >", payload?.toJS());

  fetch(url, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.blob())
    .then((blob) => blobToDownload(blob, `Carbon reduction plan.${format}`))
    .catch((err) => console.warn("Error getting report", err));
};

function InputField({ _key, label, state, setState, helpMessage }) {
  let [_id, setId] = useState(`${uuidv4()}`);
  let value = state.get(_key) || "";
  return (
    <div className="form-group">
      <label htmlFor={_id}>
        {label} <p className="text-muted m-0">{helpMessage}</p>
      </label>
      <input
        id={_id}
        className="form-control"
        type="text"
        placeholder=""
        value={value}
        onChange={(e) => setState(state.set(_key, e.target.value))}
      />
    </div>
  );
}

function TextAreaField({ _key, label, placeholder, state, setState }) {
  let [_id, setId] = useState(`${uuidv4()}`);
  let value = state.get(_key) || "";
  return (
    <div className="form-group">
      <label htmlFor={_id}>{label}</label>
      <textarea
        className="form-control"
        id={_id}
        rows="3"
        placeholder={placeholder || ""}
        value={value}
        onChange={(e) => setState(state.set(_key, e.target.value))}
      />
    </div>
  );
}

function TinyMCEInputField({ _key, label, state, setState }) {
  return (
    <ErrorBoundary>
      <div className={`form-group`}>
        <label>{label}</label>
        <TinyMCE initialValue={initialContent} init={config} onEditorChange={(v) => setState(state.set(_key, v))} />
        <ItemErrors error={error} />
      </div>
    </ErrorBoundary>
  );
}

function ListItem({ _key, item, index, state, setState, values }) {
  return (
    <li>
      {item}
      <span
        className="ml-2"
        style={{ cursor: "pointer" }}
        onClick={() => {
          let newValues = values.delete(index);
          setState(state.setInPath(`${_key}.data`, newValues));
        }}
      >
        <Octicon name={"x"} />
      </span>
    </li>
  );
}

function OptionItem({ item, index }) {
  return <option>{item.name || item}</option>;
}

function OptionsSelect(props) {
  let { _key, options, values, state, setState, listType = "list_ul" } = props;
  if (!options.size) {
    return null;
  }
  return (
    <select
      className="form-control"
      value=""
      onChange={(e) => {
        let newValues = values.push(e.target.value);
        setState(state.setInPath(_key, Traec.Im.fromJS({ type: listType, data: newValues })));
      }}
    >
      <option>Select items to add</option>
      {options.map((item, i) => (
        <OptionItem key={i} item={item} />
      ))}
    </select>
  );
}

function ExtraOptionItems(props) {
  let { _key, state, setState, listType = "list_ul" } = props;
  let [value, setValue] = useState("");
  let values = state.getInPath(`${_key}.data`) || Traec.Im.List();
  //console.log("valuesHere", values?.toJS());

  return (
    <div className="form-group">
      <div className="d-flex flex-row">
        <input
          className="form-control mr-1"
          type="text"
          placeholder={`Add other items`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <button
          className="btn btn-secondary btn-sm"
          onClick={(e) => {
            e.preventDefault();
            let newValues = values.push(value);
            setState(state.setInPath(_key, Traec.Im.fromJS({ type: listType, data: newValues })));
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
}

function SelectField(props) {
  let { _key, label, options, state, listType = "list_ul", helpMessage } = props;
  let [_id, setId] = useState(`${uuidv4()}`);
  let values = state.getInPath(`${_key}.data`) || Traec.Im.List();

  let _options = Traec.Im.fromJS(options || []).filter((i) => !values.includes(i));

  let ListComponent = listType.includes("_ul") ? "ul" : "ol";

  return (
    <div className="form-group">
      <label htmlFor={_id}>
        {label} <p className="text-muted m-0">{helpMessage}</p>
      </label>

      <ListComponent>
        {values?.map((item, i) => (
          <ListItem {...props} key={i} item={item} index={i} values={values} />
        ))}
      </ListComponent>
      <OptionsSelect {...props} options={_options} values={values} />
    </div>
  );
}

function TableRow({ rowData, CellComponent = "td" }) {
  if (!rowData) {
    return null;
  }
  return (
    <tr>
      {rowData.map((item, i) => (
        <CellComponent key={i}>{item}</CellComponent>
      ))}
    </tr>
  );
}

const getInitialIndicatorTable = (latestPeriod) => {
  let indicators = latestPeriod.get("INDICATOR_RAG_DATA");
  return indicators
    .toList()
    .map((i) => Traec.Im.List([i.get("name"), i.get("value")]))
    .unshift(Traec.Im.fromJS(["Carbon Source", "kWh", "Cost"]));
};

const tableTemplate = Traec.Im.fromJS([
  ["Carbon Source", "Emissions (tCO2e)", "Cost (GBP)"],
  ["Electricity", "value:Emissions from electricity (tCO2e)", "value:Cost of electricity (GBP)"],
  ["Gas", "value:Emissions from mains gas (tCO2e)", "value:Cost of mains gas (GBP)"],
  ["Water", "value:Emissions from water (tCO2e)", "value:Cost of water consumption (GBP)"],
  ["Waste", "value:Emissions from waste (tCO2e)", "value:Cost of waste (GBP)"],
  ["Fossil fuels", "value:Emissions from fossil fuels (tCO2e)", "value:Cost of fossil fuels (GBP)"],
  ["Material goods", "value:Emissions from material goods (tCO2e)", "value:Cost of material goods (GBP)"],
  [
    "Business travel",
    "value:Emissions from business travel & employee commuting (tCO2e)",
    "value:Cost of business travel & employee commuting (GBP)",
  ],
  ["Company vehicles", "value:Emissions from company vehicles (tCO2e)", "value:Cost of company vehicles (GBP)"],
  [
    "Other activities",
    "value:Emissions from business travel & employee commuting (tCO2e)",
    "value:Cost of other activities (GBP)",
  ],
  ["Total", "value:Total emissions (tCO2e)", "value:Total cost (GBP)"],
]);

const getIndicatorValue = (name, indicators) =>
  indicators
    .toList()
    .filter((i) => i.get("name") == name)
    ?.first()
    ?.get("value") || "";

const subIndicatorValues = (data, indicators) => {
  //console.log("dataWithoutImmutable", data);
  if (Traec.Im.List.isList(data)) {
    //console.log("dataWithImmutable", data?.toJS());
    return data.map((i) => subIndicatorValues(i, indicators));
  }
  if (typeof data === "string") {
    if (data.startsWith("value:")) {
      let indicatorName = data.split("value:").pop();
      let value = getIndicatorValue(indicatorName, indicators);
      if (!(value === null || value === undefined || value === "")) {
        if (indicatorName.includes("GBP")) {
          value = new Intl.NumberFormat("en-UK", {
            style: "currency",
            currency: "GBP",
          }).format(value);
        } else {
          value = setPrecision(value);
        }
      }
      //console.log("Got value for indicator", indicatorName, value);
      //if (value.length === 0) (value = "N/A"), console.log("whatsTheValue", value);
      return value;
    } else {
      return data;
    }
  }
  return data;
};

const getIndicatorTableFromTemplate = (indicators) => {
  //console.log("Generating indicator table from template", indicators?.toJS());
  return tableTemplate.map((i) => subIndicatorValues(i, indicators));
};

const valueToFixed = (value) => {
  if (!value) return (0.0).toFixed(1);
  return parseFloat(value)?.toFixed(1);
};

const getPieChartImageTag = (indicators) => {
  let pieChartLabels = ["Scope 1 emissions (tCO2e)", "Scope 2 emissions (tCO2e)", "Scope 3 emissions (tCO2e)"];
  let pieChartValues = pieChartLabels.map((name) => getIndicatorValue(name, indicators));
  let pieLabelsAndValues = pieChartLabels.map(
    (label, i) => `${label.substring(0, label.length - 18)}: ${valueToFixed(pieChartValues[i])} (tCO2e)`
  );
  //console.log("getPieChartImageTag >", pieLabelsAndValues, pieChartValues);

  let pieChartImageTag = `<img src="https://ibzijetnp6.execute-api.eu-west-2.amazonaws.com/chartjs?labels=${pieLabelsAndValues.join()}&values=${pieChartValues.join()}" />`;

  // let urlSafeImageTag = Buffer.from(JSON.stringify(pieChartImageTag)).toString("base64url");
  // let decodedImageTag = JSON.parse(Buffer.from(urlSafeImageTag, "base64url").toString());
  // //console.log("encoded >", urlSafeImageTag, "decoded >", decodedImageTag);

  return pieChartImageTag;
};

function IndicatorTable(props) {
  let { _type, _typeId, state, setState, filters } = props;

  let _key = "indicator_table";
  let tableData = state.getInPath(`${_key}.data`) || Traec.Im.List();
  console.log("IndicatorTable has data", state, tableData);

  let { data, isLoading, lastUpdate, triggerCacheUpdate, isRetrying } = usePostCache(
    `/api/dashboard/${_type}/${_typeId}/rags`,
    {
      filters: filters,
    }
  );

  useEffect(() => {
    //console.log("IndicatorTable useEffect", data?.toJS());
    let indicators = data?.get("indicators");
    if (indicators) {
      let initialTableData = getIndicatorTableFromTemplate(indicators);
      let total_carbon_footprint = getIndicatorValue("Total emissions (tCO2e)", indicators);

      let test_chart = getPieChartImageTag(indicators);
      console.log(test_chart, "test_chart");

      //console.log("IndicatorTable setting initial table data", initialTableData?.toJS());
      setState(
        state
          .set(_key, {
            type: "table",
            data: initialTableData || Traec.Im.List(),
          })
          .set(
            "total_carbon_footprint",
            total_carbon_footprint ? setPrecision(total_carbon_footprint) : total_carbon_footprint
          )
          .set("test_chart", test_chart)
      );
    }
  }, [data]);

  if (!tableData && isLoading) {
    return <p>Loading indicator data</p>;
  }

  return (
    <>
      <label>
        Your carbon footprint
        <p className="text-muted m-0">
          The table below provides a breakdown of your emissions as will be published in your Carbon Reduction Plan.
          Module 7 from the Climate Action Hub provides support on how to gather and report your data.
        </p>
      </label>
      <span className="float-right">
        {/* Last update: {getLastUpdateFromNow(lastUpdate)} */}
        <RefreshButton trigger={triggerCacheUpdate} isRetrying={isRetrying} />
      </span>
      <table id="sbccIndicatorTable" className="table table-sm table-bordered">
        <thead>
          <TableRow rowData={tableData?.first()} CellComponent="th" />
        </thead>
        <tbody>
          {tableData.slice(1).map((item, i) => (
            <TableRow key={i} rowData={item} />
          ))}
        </tbody>
      </table>
    </>
  );
}

function SingleSelectField({ _key, label, options, state, setState }) {
  let [_id, setId] = useState(`${uuidv4()}`);
  let value = state.get(_key) || "";
  return (
    <div className="form-group">
      <label htmlFor={_id}>{label}</label>
      <select
        className="form-control"
        value={value}
        onChange={(e) => {
          setState(state.set(_key, e.target.value));
        }}
      >
        {value ? null : <option>Select an item</option>}
        {options.map((item, i) => (
          <OptionItem key={i} item={item} />
        ))}
      </select>
    </div>
  );
}

const getActions = (actions, company_sector) => {
  let sectors = Traec.Im.Set(["All sectors"]);
  if (company_sector) {
    sectors = sectors.add(company_sector);
  }
  return actions.filter((i) => Traec.Im.Set(i.sectors).intersect(sectors).size).map((i) => i.value);
};

function SBCCReport(props) {
  let { _type, _typeId, initialState, origin, filters, extraHead = null } = props;

  let [state, setState] = useState(initialState);
  let _props = { state, setState };

  let company_sector = state.get("company_sector");

  return (
    <div className="container my-5 shadow p-3 bg-white rounded">
      {extraHead}

      <button
        className="btn btn-sm btn-outline-secondary mb-3 float-right"
        onClick={() => {
          location.href = origin;
        }}
      >
        Back
      </button>

      <div style={{ clear: "both" }}></div>

      <div className="p-1">
        <InputField {..._props} _key="company_name" label="Company Name" />

        <SingleSelectField
          {..._props}
          _key="company_sector"
          label="Company sector"
          options={[
            "Agriculture, forestry and fishing",
            "Charities and non-for-profit",
            "Construction",
            "Hospitality and tourism",
            "Manufacturing",
            "Oil, gas and quarrying",
            "Professional and business services",
            "Public services",
            "Retail and wholesale",
            "Technology and communication",
            "Transport and storage",
            "Utilities",
            "Other",
          ]}
        />

        <TextAreaField
          {..._props}
          _key="activity_description"
          label="Activity description"
          placeholder="Please provide a description of your organisation, detailing the area of activity, number of employees and location."
        />

        <IndicatorTable {...props} {..._props} />

        <SelectField
          {..._props}
          _key="hotspot_selections"
          label="Carbon hotspots"
          listType="list_ul"
          helpMessage="Select your carbon hotspots from the dropdown below. Please refer to your carbon dashboards to identify these."
          options={[
            "Emissions from electricity",
            "Emissions from mains gas",
            "Emissions from water",
            "Emissions from business travel",
            "Emissions from company vehicles",
            "Emissions from fossil fuels",
            "Emissions from waste",
            "Emissions from material goods",
            "Emissions from other activities",
          ]}
        />
        <SelectField
          {..._props}
          _key="drivers_for_action"
          label="Drivers for action"
          helpMessage="Please select your top 3 reasons for taking actions to reduce your carbon emissions. Please refer to the drivers exercise from Module 2 in the Climate Action Hub"
          listType="list_ol"
          options={[
            "Competitive advantage",
            "Cost",
            "Customers",
            "Employees",
            "Legislation",
            "Organisational ethics",
            "Risk",
            "Suppliers",
            "Other",
          ]}
        />
        <ExtraOptionItems {..._props} _key="drivers_for_action" listType="list_ol" label="Other drivers for action" />

        <SelectField
          {..._props}
          _key="quick_wins"
          label="Quick wins (0-1 month)"
          listType="list_ul"
          helpMessage="Module 10 in the Climate Action Hub will provide support in selecting your carbon reduction actions. Please choose between 3 and 5 realistic actions."
          options={getActions(quick_wins, company_sector)}
        />
        <ExtraOptionItems {..._props} _key="quick_wins" listType="list_ul" label="Other quick wins" />
        <SelectField
          {..._props}
          _key="core_actions"
          label="Core actions (2-12 months)"
          listType="list_ul"
          helpMessage="Module 10 in the Climate Action Hub will provide support in selecting your carbon reduction actions. Please choose between 3 and 5 realistic actions."
          options={getActions(core_actions, company_sector)}
        />
        <ExtraOptionItems {..._props} _key="core_actions" listType="list_ul" label="Other core actions" />
        <SelectField
          {..._props}
          _key="transformational_actions"
          label="Transformational actions (1+ years)"
          listType="list_ul"
          helpMessage="Module 10 in the Climate Action Hub will provide support in selecting your carbon reduction actions. Please choose between 3 and 5 realistic actions."
          options={getActions(transformations, company_sector)}
        />
        <ExtraOptionItems
          {..._props}
          _key="transformational_actions"
          listType="list_ul"
          label="Other transformational actions"
        />
        <InputField
          {..._props}
          _key="lt_reduction_percent"
          label="Long term carbon reduction target (percent)"
          helpMessage="Module 9 in the Climate Action Hub will provide support on how to set your carbon reduction target"
        />
        <InputField
          {..._props}
          _key="lt_reduction_years"
          label="Long term carbon reduction timeframe (years)"
          helpMessage="Module 9 in the Climate Action Hub will provide support on how to set your carbon reduction target"
        />
        <InputField
          {..._props}
          _key="reduction_percent_2030"
          label="Carbon reduction target for 2030 (percent)"
          helpMessage="Module 9 in the Climate Action Hub will provide support on how to set your carbon reduction target"
        />
        <div className="form-group">
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={() => {
              location.href = origin;
            }}
          >
            Back
          </button>
          <div className="dropdown float-right">
            <button
              className="btn btn-sm btn-primary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              Create plan
            </button>
            <div className="dropdown-menu">
              <a className="dropdown-item" onClick={(e) => makeReport(_typeId, state, "docx")}>
                Word (docx)
              </a>
              <a className="dropdown-item" onClick={(e) => makeReport(_typeId, state, "pdf")}>
                PDF
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function SBCCReportLoader(props) {
  let { _type, _typeId } = props.match?.params;

  let origin = new URLSearchParams(window.location.search).get("src") || `/${_type}/${_typeId}`;

  let objUrl = _typeId && _typeId ? `/api/${_type}/${_typeId}/` : null;
  let { url, data, isLoading } = useApi(`/api/report/sbcc/${_typeId}/state`);
  let { data: objData, isLoading: isLoadingObject, error } = useApi(objUrl);

  let yearSelectorProps = useYearSelector();

  //console.log("Got object data", objUrl, objData?.toJS(), error);

  if (isLoading || isLoadingObject) {
    return <TipsSpinner message="Loading saved report" />;
  }

  if (_type == "project") {
    _typeId = objData?.get("trackers")?.first()?.get("uid");
  }

  let initialState = Traec.Im.Map({
    company_name: objData.get("name") || "",
    baseline_year: "2022",
    lt_reduction_percent: 70,
    lt_reduction_years: 23,
    reduction_percent_2030: 25,
    test_chart: "",
  }).merge(data);

  // Set filters from selected dateRange
  let { dateRange } = yearSelectorProps;
  let filters = Im.Map({
    start: dateRange.get("from_date"),
    end: dateRange.get("to_date"),
  });

  //console.log("Rendering SBCCReport with data from", url, filters?.toJS(), data?.toJS(), initialState?.toJS());

  return (
    <>
      <SBCCReport
        _type={_type}
        _typeId={_typeId}
        initialState={initialState}
        origin={origin}
        filters={filters}
        extraHead={<YearSelector {...yearSelectorProps} fromYear={2022} />}
      />
    </>
  );
}
